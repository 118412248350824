import ProfileIcon from '@mui/icons-material/AccountBox';

import ProfileEdit from './ProfileEdit';

const Profile = {
  edit: ProfileEdit,
  icon: ProfileIcon,
};

export default Profile;
