import { TranslationMessages } from 'react-admin';
import frenchMessages from 'ra-language-french';

const customFrenchhMessages: TranslationMessages = {
  ...frenchMessages,
  ra: {
    ...frenchMessages.ra,
    auth: {
      ...frenchMessages.ra.auth,
      email: 'Email',
    },
  },
  month: {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre',
  },
  component: {
    s3ImageInput: {
      action: {
        uploading: 'Chargement en cours...',
        filesNotSupported: '%{filesCount} fichier(s) non supporté(s)',
        uploadFileError: "Échec de l'importation du fichier",
        deleteFileError: "Échec de la suppression de l'ancien fichier",
        selectRequiredError: 'Vous devez sélectionner au moins 1 image',
      },
    },
    richText: {
      imageButtons: {
        label: 'Image',
        dialogTitle: "URL de l'image",
      },
      colorButtons: {
        colorLabel: 'Couleur',
        highlightLabel: 'Fond',
      },
    },
  },
  screen: {
    dashboard: {
      field: {
        postsTypes: 'Types de créations',
        postsByDate: 'Créations par date',
        postsByAuthor: 'Meilleurs contributeurs',
        postsByTag: 'Labels populaires',
        drafts: 'Brouillons',
      },
    },
    configurations: {
      name: 'Préférences',
      field: {
        language: 'Langue',
        theme: {
          name: 'Theme',
          light: 'Clair',
          dark: 'Obscur',
        },
      },
    },
  },
  resource: {
    global: {
      field: {
        title: 'Title',
        subtitle: 'Sous-titre',
        date: 'Date',
        name: 'Nom',
        value: 'Valeur',
        thumbnail: 'Vignette',
      },
      action: {
        copy: 'Copier',
        textCopied: '%{textName} copié dans le presse-papier avec succés',
        copyError: 'Échec de la copie de %{textName} dans le presse-papier',
      },
    },
    common: {
      field: {
        id: 'id',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
      },
    },
    administration: {
      name: 'Administration',
    },
    profile: {
      name: 'Profil',
      action: {
        edit: {
          avatar: 'Vous pouvez le personnaliser sur',
        },
        delete: {
          button: 'Supprimer mon compte',
          title: 'Êtes-vous sûr(e) de vouloir supprimer votre compte ?',
          message: 'Toutes les créations liées seront également supprimées.',
        },
      },
    },
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      field: {
        firstName: 'Prénom',
        lastName: 'Nom',
        fullName: 'Nom complet',
        email: 'Email',
        role: {
          name: 'Rôle',
          admin: 'Admin',
          publisher: 'Éditeur',
          editor: 'Rédacteur',
        },
        isActivated: 'Activé',
        unactivated: 'Inactivé',
      },
      action: {
        activate: 'Activer',
        userActivated: 'Utilisateur activé',
      },
    },
    'api-keys': {
      name: 'Clef API |||| Clefs API',
    },
    posts: {
      name: 'Création |||| Créations',
      field: {
        status: {
          name: 'Statut',
          draft: 'Brouillon',
          published: 'Publié',
          archived: 'Archivé',
        },
        createdBy: 'Créé par',
        updatedBy: 'Modifié par',
        author: 'Auteur',
        tag: 'Label',
      },
      action: {
        publish: 'Publier',
        archive: 'Archiver',
        statusUpdated: 'Statut mis à jour avec succès',
      },
    },
    tags: {
      name: 'Label |||| Labels',
      field: {
        existing: 'Tags existants',
        relatedPosts: 'Création(s) liée(s)',
      },
      action: {
        fetchExistingError: 'Échec de la récupération des tags existants',
      },
    },
    articles: {
      name: 'Article |||| Articles',
      field: {
        content: 'Description',
      },
    },
    stories: {
      name: 'Histoire |||| Histoires',
      field: {
        content: 'Contenu',
      },
    },
    galleries: {
      name: 'Galerie |||| Galeries',
      field: {
        content: 'Résumé',
        cover: 'Couverture',
        pictures: 'Photos',
        isPinned: 'Épinglé',
      },
    },
    critics: {
      name: 'Critique |||| Critiques',
      field: {
        type: {
          name: 'Type',
          game: 'Jeu',
          book: 'Livre',
          movie: 'Film',
          tvShow: 'Série',
          show: 'Spectacle',
          exposition: 'Expo',
        },
        rating: 'Note',
        content: 'Avis',
      },
    },
  },
};

export default customFrenchhMessages;
