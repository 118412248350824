import StoryIcon from '@mui/icons-material/Book';

import StoriesList from './StoriesList';
import StoryCreate from './StoryCreate';
import StoryEdit from './StoryEdit';

const Story = {
  list: StoriesList,
  create: StoryCreate,
  edit: StoryEdit,
  icon: StoryIcon,
};

export default Story;
