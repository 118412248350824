import UserIcon from '@mui/icons-material/People';

import UsersList from './UsersList';
import UserEdit from './UserEdit';

const User = {
  list: UsersList,
  edit: UserEdit,
  icon: UserIcon,
};

export default User;
