import * as React from 'react';
import PostsImageList from '../post/PostsImageList';
import { RaRecord } from 'react-admin';
import criticTypeChoices from '../../../choices/critics/critic-type.choices';
import { ImageListProps } from '../common/CustomImageList';

const CriticsImageList: React.FC<ImageListProps> = ({
  rowHeight,
  cols,
  sx,
}) => (
  <PostsImageList
    rowHeight={rowHeight}
    cols={cols}
    sx={sx}
    resource="critics"
    imageSource="thumbnail"
    getSubtitleFromRecord={(record: RaRecord) => `${record.rating} / 10`}
    postTypeChoices={criticTypeChoices}
  />
);

export default CriticsImageList;
