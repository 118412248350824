import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

const FormControl: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box
      className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth"
      sx={{ mb: 1.5 }}
    >
      {React.Children.map(children, (child) => {
        // Passes props to children if possible (such as record property)
        if (React.isValidElement(child)) {
          return React.cloneElement(child, rest);
        }
        return child;
      })}
    </Box>
  );
};

export default FormControl;
