import * as React from 'react';
import { Title, useAuthenticated, usePermissions } from 'react-admin';
import { Box, Theme, useMediaQuery } from '@mui/material';
import PostDraftsWidget from '../components/dashboard/PostDraftsWidget';
import UsersWidget from '../components/dashboard/UsersWidget';
import PostsTypesChart from '../components/dashboard/PostsTypesChart';
import articles from '../resources/articles';
import stories from '../resources/stories';
import galleries from '../resources/galleries';
import critics from '../resources/critics';
import PostsByDateChart from '../components/dashboard/PostsByDateChart';
import PostsByAuthorChart from '../components/dashboard/PostsByAuthorChart';
import { AuthRoles } from '../../utils/enum';
import PostsByTagChart from '../components/dashboard/PostsByTagChart';
import ApiKeysWidget from '../components/dashboard/ApiKeysWidget';
import ChartCard from '../components/dashboard/ChartCard';

const containerStyle = { display: 'flex', flexDirection: 'row', gap: 2 };
const columnStyle = { flex: 1 };

const Dashboard: React.FC = () => {
  useAuthenticated();
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true },
  );
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  return permissions ? (
    <Box sx={{ mt: 2 }}>
      <Title title="ra.page.dashboard" />
      {isSmall ? (
        <Box sx={containerStyle}>
          <Box sx={columnStyle}>
            {permissions === AuthRoles.ADMIN ? (
              <>
                <Box sx={containerStyle}>
                  <Box sx={columnStyle}>
                    <ApiKeysWidget />
                  </Box>
                  <Box sx={columnStyle}>
                    <UsersWidget isActivated={0} />
                  </Box>
                </Box>
                <Box sx={containerStyle}>
                  <Box sx={columnStyle}>
                    <UsersWidget role={AuthRoles.PUBLISHER} />
                  </Box>
                  <Box sx={columnStyle}>
                    <UsersWidget role={AuthRoles.EDITOR} />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box sx={containerStyle}>
                  <Box sx={columnStyle}>
                    <PostDraftsWidget
                      resource="articles"
                      icon={articles.icon}
                    />
                  </Box>
                  <Box sx={columnStyle}>
                    <PostDraftsWidget resource="stories" icon={stories.icon} />
                  </Box>
                </Box>
                <Box sx={containerStyle}>
                  <Box sx={columnStyle}>
                    <PostDraftsWidget
                      resource="galleries"
                      icon={galleries.icon}
                    />
                  </Box>
                  <Box sx={columnStyle}>
                    <PostDraftsWidget resource="critics" icon={critics.icon} />
                  </Box>
                </Box>
              </>
            )}
            <ChartCard label="screen.dashboard.field.postsByDate">
              <PostsByDateChart />
            </ChartCard>
            <ChartCard label="screen.dashboard.field.postsTypes">
              <PostsTypesChart />
            </ChartCard>
            {permissions === AuthRoles.ADMIN ? (
              <ChartCard label="screen.dashboard.field.postsByAuthor">
                <PostsByAuthorChart />
              </ChartCard>
            ) : (
              <ChartCard label="screen.dashboard.field.postsByTag">
                <PostsByTagChart />
              </ChartCard>
            )}
          </Box>
        </Box>
      ) : !isLarge ? (
        <>
          {permissions === AuthRoles.ADMIN ? (
            <>
              <Box sx={containerStyle}>
                <Box sx={columnStyle}>
                  <ApiKeysWidget />
                </Box>
                <Box sx={columnStyle}>
                  <UsersWidget isActivated={0} />
                </Box>
              </Box>
              <Box sx={containerStyle}>
                <Box sx={columnStyle}>
                  <UsersWidget role={AuthRoles.PUBLISHER} />
                </Box>
                <Box sx={columnStyle}>
                  <UsersWidget role={AuthRoles.EDITOR} />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box sx={containerStyle}>
                <Box sx={columnStyle}>
                  <PostDraftsWidget resource="articles" icon={articles.icon} />
                </Box>
                <Box sx={columnStyle}>
                  <PostDraftsWidget resource="stories" icon={stories.icon} />
                </Box>
              </Box>
              <Box sx={containerStyle}>
                <Box sx={columnStyle}>
                  <PostDraftsWidget
                    resource="galleries"
                    icon={galleries.icon}
                  />
                </Box>
                <Box sx={columnStyle}>
                  <PostDraftsWidget resource="critics" icon={critics.icon} />
                </Box>
              </Box>
            </>
          )}
          <Box sx={containerStyle}>
            <Box sx={columnStyle}>
              <ChartCard label="screen.dashboard.field.postsByDate">
                <PostsByDateChart />
              </ChartCard>
            </Box>
          </Box>
          <Box sx={containerStyle}>
            <Box sx={columnStyle}>
              <ChartCard label="screen.dashboard.field.postsTypes">
                <PostsTypesChart />
              </ChartCard>
            </Box>
          </Box>
          <Box sx={containerStyle}>
            <Box sx={columnStyle}>
              {permissions === AuthRoles.ADMIN ? (
                <ChartCard label="screen.dashboard.field.postsByAuthor">
                  <PostsByAuthorChart />
                </ChartCard>
              ) : (
                <ChartCard label="screen.dashboard.field.postsByTag">
                  <PostsByTagChart />
                </ChartCard>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          {permissions === AuthRoles.ADMIN ? (
            <Box sx={containerStyle}>
              <Box sx={columnStyle}>
                <ApiKeysWidget />
              </Box>
              <Box sx={columnStyle}>
                <UsersWidget isActivated={0} />
              </Box>
              <Box sx={columnStyle}>
                <UsersWidget role={AuthRoles.PUBLISHER} />
              </Box>
              <Box sx={columnStyle}>
                <UsersWidget role={AuthRoles.EDITOR} />
              </Box>
            </Box>
          ) : (
            <Box sx={containerStyle}>
              <Box sx={columnStyle}>
                <PostDraftsWidget resource="articles" icon={articles.icon} />
              </Box>
              <Box sx={columnStyle}>
                <PostDraftsWidget resource="stories" icon={stories.icon} />
              </Box>
              <Box sx={columnStyle}>
                <PostDraftsWidget resource="galleries" icon={galleries.icon} />
              </Box>
              <Box sx={columnStyle}>
                <PostDraftsWidget resource="critics" icon={critics.icon} />
              </Box>
            </Box>
          )}
          <Box sx={containerStyle}>
            <Box sx={columnStyle}>
              <ChartCard label="screen.dashboard.field.postsByDate">
                <PostsByDateChart />
              </ChartCard>
            </Box>
          </Box>
          <Box sx={containerStyle}>
            <Box sx={columnStyle}>
              <ChartCard label="screen.dashboard.field.postsTypes">
                <PostsTypesChart />
              </ChartCard>
            </Box>
            <Box sx={columnStyle}>
              {permissions === AuthRoles.ADMIN ? (
                <ChartCard label="screen.dashboard.field.postsByAuthor">
                  <PostsByAuthorChart />
                </ChartCard>
              ) : (
                <ChartCard label="screen.dashboard.field.postsByTag">
                  <PostsByTagChart />
                </ChartCard>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  ) : null;
};

export default Dashboard;
