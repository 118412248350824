import * as React from 'react';
import { Box } from '@mui/material';
import { RaRecord } from 'react-admin';
import Gravatar from 'react-gravatar';

interface Props {
  record: RaRecord;
  size?: number;
  rounded?: boolean;
}

const GravatarField: React.FC<Props> = ({
  record,
  size = 75,
  rounded = false,
}) =>
  record ? (
    <Box sx={{ '& img': { borderRadius: rounded ? '50%' : 0 } }}>
      <Gravatar email={record.email} size={size} default="mm" />
    </Box>
  ) : null;

export default GravatarField;
