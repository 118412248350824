import { defaultTheme } from 'react-admin';
import { ThemeName } from '../../utils/enum';

// Switching the theme mode is only a single property value change (palette.mode).

export const lightTheme = {
  palette: {
    mode: ThemeName.LIGHT,
    primary: {
      light: '#9a67ea',
      main: '#673ab7',
      dark: '#320b86',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#80e27e',
      main: '#4caf50',
      dark: '#087f23',
      contrastText: '#ffffff',
    },
    background: {
      default: '#eeeeee',
      paper: '#ffffff',
    },
  },
  components: defaultTheme.components,
};

export const darkTheme = {
  palette: {
    mode: ThemeName.DARK,
    primary: {
      light: '#80d6ff',
      main: '#42a5f5',
      dark: '#0077c2',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#62727b',
      main: '#37474f',
      dark: '#102027',
      contrastText: '#ffffff',
    },
    background: {
      default: '#222222',
      paper: '#000000',
    },
  },
  components: {
    ...defaultTheme.components,
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          backgroundColor: '#000000',
        },
      },
    },
  },
};
