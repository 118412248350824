import GalleryIcon from '@mui/icons-material/PhotoLibrary';

import GalleriesList from './GalleriesList';
import GalleryCreate from './GalleryCreate';
import GalleryEdit from './GalleryEdit';

const Gallery = {
  list: GalleriesList,
  create: GalleryCreate,
  edit: GalleryEdit,
  icon: GalleryIcon,
};

export default Gallery;
