import * as React from 'react';
import { List, Pagination, SelectInput, useTranslate } from 'react-admin';
import CustomImageList from '../../components/common/CustomImageList';
import CriticsImageList from '../../components/critics/CriticsImageList';
import criticTypeChoices from '../../../choices/critics/critic-type.choices';
import postFilters from '../../components/post/postFilters';

const CriticsList = () => {
  const translate = useTranslate();

  return (
    <List
      title={translate(`resource.critics.name`, {
        smart_count: 2,
      })}
      filters={[
        ...postFilters,
        <SelectInput
          source="type"
          label="resource.critics.field.type.name"
          choices={criticTypeChoices}
          alwaysOn
        />,
      ]}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={12}
      pagination={<Pagination rowsPerPageOptions={[6, 12, 24]} />}
    >
      <CustomImageList loadedImageList={<CriticsImageList />} rowHeight={320} />
    </List>
  );
};

export default CriticsList;
