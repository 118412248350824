import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  ra: {
    ...englishMessages.ra,
    auth: {
      ...englishMessages.ra.auth,
      email: 'Email',
    },
  },
  month: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },
  component: {
    s3ImageInput: {
      action: {
        uploading: 'Uploading in progress...',
        filesNotSupported: '%{filesCount} file(s) not supported',
        uploadFileError: 'Error when trying to upload file',
        deleteFileError: 'Error when trying to delete old file',
        selectRequiredError: 'You must select at least 1 image',
      },
    },
    richText: {
      imageButtons: {
        label: 'Image',
        dialogTitle: 'Image URL',
      },
      colorButtons: {
        colorLabel: 'Color',
        highlightLabel: 'Highlight',
      },
    },
  },
  screen: {
    dashboard: {
      field: {
        postsTypes: 'Types of posts',
        postsByDate: 'Posts by date',
        postsByAuthor: 'Top contributors',
        postsByTag: 'Trending Tags',
        drafts: 'Drafts',
      },
    },
    configurations: {
      name: 'Configuration',
      field: {
        language: 'Language',
        theme: {
          name: 'Theme',
          light: 'Light',
          dark: 'Dark',
        },
      },
    },
  },
  resource: {
    global: {
      field: {
        title: 'Title',
        subtitle: 'Subtitle',
        date: 'Date',
        name: 'Name',
        value: 'Value',
        thumbnail: 'Thumbnail',
      },
      action: {
        copy: 'Copy',
        textCopied: '%{textName} successfully copied into clipboard',
        copyError: 'Error when trying to copy %{textName} into clipboard',
      },
    },
    common: {
      field: {
        id: 'id',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
    },
    administration: {
      name: 'Administration',
    },
    profile: {
      name: 'Profile',
      action: {
        edit: {
          avatar: 'You can customize it using',
        },
        delete: {
          button: 'Delete my account',
          title: 'Are you sure you want to delete your account?',
          message: 'All related posts will also be deleted.',
        },
      },
    },
    users: {
      name: 'User |||| Users',
      field: {
        firstName: 'First name',
        lastName: 'Last name',
        fullName: 'Full name',
        email: 'Email',
        role: {
          name: 'Role',
          admin: 'Admin',
          publisher: 'Publisher',
          editor: 'Editor',
        },
        isActivated: 'Is activated',
        unactivated: 'Unactivated',
      },
      action: {
        activate: 'Activate',
        userActivated: 'User activated',
      },
    },
    'api-keys': {
      name: 'API key |||| API keys',
    },
    posts: {
      name: 'Post |||| Posts',
      field: {
        status: {
          name: 'Status',
          draft: 'Draft',
          published: 'Published',
          archived: 'Archived',
        },
        createdBy: 'Created by',
        updatedBy: 'Updated by',
        author: 'Author',
        tag: 'Tag',
      },
      action: {
        publish: 'Publish',
        archive: 'Archive',
        statusUpdated: 'Status successfully updated',
      },
    },
    tags: {
      name: 'Tag |||| Tags',
      field: {
        existing: 'Existing tags',
        relatedPosts: 'Related post(s)',
      },
      action: {
        fetchExistingError: 'Error when trying to fetch existing tags',
      },
    },
    articles: {
      name: 'Article |||| Articles',
      field: {
        content: 'Description',
      },
    },
    stories: {
      name: 'Story |||| Stories',
      field: {
        content: 'Content',
      },
    },
    galleries: {
      name: 'Gallery |||| Galleries',
      field: {
        content: 'Short description',
        cover: 'Cover',
        pictures: 'Pictures',
        isPinned: 'Is pinned',
      },
    },
    critics: {
      name: 'Critic |||| Critics',
      field: {
        type: {
          name: 'Type',
          game: 'Game',
          book: 'Book',
          movie: 'Movie',
          tvShow: 'TV show',
          show: 'Show',
          exposition: 'Exposition',
        },
        rating: 'Rating',
        content: 'Opinion',
      },
    },
  },
};

export default customEnglishMessages;
