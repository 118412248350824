export enum ThemeName {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum PostStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum CriticType {
  GAME = 'GAME',
  BOOK = 'BOOK',
  MOVIE = 'MOVIE',
  TV_SHOW = 'TV_SHOW',
  SHOW = 'SHOW',
  EXPOSITION = 'EXPOSITION',
}

export enum AuthRoles {
  ADMIN = 'ADMIN',
  PUBLISHER = 'PUBLISHER',
  EDITOR = 'EDITOR',
}
