import * as React from 'react';
import { Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

interface Props {
  href: string;
  target?: 'self' | 'blank';
  children?: React.ReactNode;
}

const CustomLink: React.FC<Props> = ({ href, target = 'self', children }) => {
  const rel = target === 'blank' ? 'noopener' : undefined;

  return (
    <Link
      href={href}
      target={`_${target}`}
      rel={rel}
      sx={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {children}
      {target === 'blank' && (
        <LaunchIcon
          sx={{
            fontSize: 15,
            ml: 1,
          }}
        />
      )}
    </Link>
  );
};

export default CustomLink;
