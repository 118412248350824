import * as React from 'react';
import {
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  ToolbarProps,
  useRecordContext,
} from 'react-admin';
import ExistingTags from '../../components/tags/ExistingTags';
import PostReferenceManyFields from '../../components/post/PostReferenceManyField';
import CustomToolbar from '../../components/common/CustomToolbar';
import DeleteTagButton from '../../components/tags/DeleteTagButton';
import { useFormState } from 'react-hook-form';

const ApiKeyTitle = () => {
  const record = useRecordContext<TagType>();
  return <span>{record ? record.name : ''}</span>;
};

const TagToolbar: React.FC<Omit<ToolbarProps, 'width'>> = (props) => {
  const { isDirty, isValid } = useFormState();

  return (
    <CustomToolbar>
      <SaveButton disabled={!isDirty || !isValid} />
      <DeleteTagButton />
    </CustomToolbar>
  );
};

const TagEdit = () => {
  return (
    <Edit title={<ApiKeyTitle />}>
      <SimpleForm toolbar={<TagToolbar />}>
        <TextInput disabled source="id" label="resource.common.field.id" />
        <ExistingTags />
        <TextInput
          source="name"
          label="resource.global.field.name"
          isRequired
          validate={required()}
        />
        <PostReferenceManyFields reference="articles" perPage={5} />
        <PostReferenceManyFields reference="stories" perPage={5} />
        <PostReferenceManyFields reference="galleries" perPage={5} />
        <PostReferenceManyFields reference="critics" perPage={5} />
      </SimpleForm>
    </Edit>
  );
};

export default TagEdit;
