import * as React from 'react';
import { List, Pagination, SelectInput, useTranslate } from 'react-admin';
import booleanChoices from '../../../choices/common/boolean.choices';
import CustomImageList from '../../components/common/CustomImageList';
import GalleriesImageList from '../../components/galleries/GalleriesImageList';
import postFilters from '../../components/post/postFilters';

const galleryFilters = [
  ...postFilters,
  <SelectInput
    source="isPinned"
    label="resource.galleries.field.isPinned"
    choices={booleanChoices}
    alwaysOn
  />,
];

const GalleriesList = () => {
  const translate = useTranslate();

  return (
    <List
      title={translate(`resource.galleries.name`, {
        smart_count: 2,
      })}
      filters={galleryFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={12}
      pagination={<Pagination rowsPerPageOptions={[6, 12, 24]} />}
    >
      <CustomImageList loadedImageList={<GalleriesImageList />} />
    </List>
  );
};

export default GalleriesList;
