import * as React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import { AuthRoles } from '../../../utils/enum';
import customDataProvider from '../../../providers/data-provider';
import usersResource from '../../resources/users';

interface UsersWidgetProps {
  role?: AuthRoles;
  isActivated?: number;
}

const UsersWidget: React.FC<UsersWidgetProps> = ({
  role,
  isActivated = 1,
}: UsersWidgetProps) => {
  const translate = useTranslate();
  const [users, setUsers] = React.useState<any>({ data: [], total: 0 });

  React.useEffect(() => {
    customDataProvider
      .getList('users', {
        pagination: {
          page: 1,
          perPage: 10,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter: {
          role,
          isActivated,
        },
      })
      .then((response) => setUsers(response));
  }, [role, isActivated]);

  return (
    <CardWithIcon
      icon={usersResource.icon}
      title={
        !isActivated
          ? translate('resource.users.field.unactivated')
          : role
          ? translate(`resource.users.field.role.${role?.toLowerCase()}`)
          : translate('resource.users.name', { smart_count: 2 })
      }
      value={users.total}
    >
      <List>
        {users.data.map((user: UserType) => (
          <ListItem
            button
            to={`/users/${user.id}`}
            component={Link}
            key={user.id}
          >
            <ListItemText primary={`${user.firstName} ${user.lastName}`} />
          </ListItem>
        ))}
      </List>
    </CardWithIcon>
  );
};

export default UsersWidget;
