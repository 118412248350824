import * as React from 'react';
import PostsImageList from '../post/PostsImageList';
import { RaRecord, useTranslate } from 'react-admin';
import { ImageListProps } from '../common/CustomImageList';

const GalleriesImageList: React.FC<ImageListProps> = ({
  rowHeight,
  cols,
  sx,
}) => {
  const translate = useTranslate();

  return (
    <PostsImageList
      rowHeight={rowHeight}
      cols={cols}
      sx={sx}
      resource="galleries"
      imageSource="cover"
      getSubtitleFromRecord={(record: RaRecord) =>
        `${record.pictures.length} ${translate(
          'resource.galleries.field.pictures',
        )}`
      }
      isPinnable
    />
  );
};

export default GalleriesImageList;
