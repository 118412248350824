import TagIcon from '@mui/icons-material/LocalOffer';

import TagsList from './TagsList';
import TagCreate from './TagCreate';
import TagEdit from './TagEdit';

const Tag = {
  list: TagsList,
  create: TagCreate,
  edit: TagEdit,
  icon: TagIcon,
};

export default Tag;
