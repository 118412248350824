import * as React from 'react';
import {
  DeleteButton,
  DeleteButtonProps,
  DeleteWithConfirmButton,
  useRecordContext,
  useTranslate,
} from 'react-admin';

const DeleteTagButton: React.FC<DeleteButtonProps> = (props) => {
  const record = useRecordContext(props);
  const translate = useTranslate();

  return record ? (
    record.postsCount > 0 ? (
      <DeleteWithConfirmButton
        confirmTitle={translate('ra.message.delete_title', {
          name: translate(`resource.tags.name`, {
            smart_count: 1,
          }),
          id: record.name,
        })}
      />
    ) : (
      <DeleteButton />
    )
  ) : null;
};

export default DeleteTagButton;
