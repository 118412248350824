import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

const ApiKeyCreate = () => {
  const translate = useTranslate();

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.api-keys.name', {
          smart_count: 1,
        }),
      })}
    >
      <SimpleForm>
        <TextInput
          source="name"
          label="resource.global.field.name"
          isRequired
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default ApiKeyCreate;
