import * as React from 'react';
import {
  ButtonProps,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CustomButton from './CustomButton';
import get from 'lodash/get';

// Makes the source property mandatory
interface Props extends ButtonProps {
  source: string;
  value?: string;
  textName: string;
}

const CopyButton: React.FC<Props> = (props) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  const { source, value } = props;
  const textName = translate(props.textName, {
    smart_count: 1,
  });
  let label = 'resource.global.action.copy';
  let icon = <FileCopyIcon />;
  const text = value ? value : get(record, source);
  const notify = useNotify();
  const copy = () => {
    navigator.clipboard.writeText(text).then(
      () =>
        notify('resource.global.action.textCopied', {
          type: 'info',
          messageArgs: { textName },
        }),
      () =>
        notify('resource.global.action.copyError', {
          type: 'warning',
          messageArgs: { textName },
        }),
    );
  };

  return record ? (
    <CustomButton icon={icon} label={label} onClick={copy} />
  ) : null;
};

export default CopyButton;
