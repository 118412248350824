import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslate } from 'react-admin';

interface ChartCardProps {
  label: string;
  children?: React.ReactNode;
}

const ChartCard: React.FC<ChartCardProps> = (props) => {
  const { label, children } = props;
  const translate = useTranslate();

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader title={translate(label)} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ChartCard;
