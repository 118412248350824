import * as React from 'react';
import {
  DateTimeInput,
  Edit,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import postStatusChoices from '../../../choices/post/post-status.choices';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import TagsReferenceArrayInput from '../../components/post/TagsReferenceArrayInput';
import criticTypeChoices from '../../../choices/critics/critic-type.choices';
import S3ImageInput from '../../components/common/S3ImageInput';

const CriticTitle = () => {
  const record = useRecordContext<CriticType>();
  return <span>{record ? record.title : ''}</span>;
};

const CriticEdit = () => (
  <Edit title={<CriticTitle />}>
    <SimpleForm>
      <TextInput disabled source="id" label="resource.common.field.id" />
      <DateTimeInput
        source="date"
        label="resource.global.field.date"
        defaultValue={new Date()}
        isRequired
        validate={required()}
      />
      <SelectInput
        source="type"
        label="resource.critics.field.type.name"
        choices={criticTypeChoices}
        isRequired
        validate={required()}
      />
      <TextInput
        source="title"
        label="resource.global.field.title"
        isRequired
        validate={required()}
      />
      <NumberInput
        source="rating"
        step={1}
        min={0}
        max={10}
        isRequired
        validate={required()}
      />
      <SelectInput
        source="status"
        label="resource.posts.field.status.name"
        choices={postStatusChoices}
        isRequired
        validate={required()}
      />
      <TagsReferenceArrayInput />
      <S3ImageInput
        source="thumbnail"
        label="resource.global.field.thumbnail"
        isRequired
        validate={required()}
      />
      <CustomRichTextInput
        source="content"
        label="resource.critics.field.content"
        isRequired
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default CriticEdit;
