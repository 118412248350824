import * as React from 'react';
import { Button, ButtonProps } from 'react-admin';

interface Props extends ButtonProps {
  icon: React.ReactElement;
}

const CustomButton: React.FC<Props> = ({ icon, label, onClick, disabled }) => (
  <Button label={label} onClick={onClick} disabled={disabled}>
    {icon}
  </Button>
);

export default CustomButton;
