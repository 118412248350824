import * as React from 'react';
import { SelectField, SelectFieldProps, useRecordContext } from 'react-admin';
import { useChoices } from 'ra-core';
import get from 'lodash/get';
import Chip from './Chip';

// Makes the source property mandatory
interface Props extends SelectFieldProps {
  source: string;
}

const SelectChipField: React.FC<Props> = (props) => {
  const { source, choices, optionText, optionValue, translateChoice } = props;
  const record = useRecordContext(props);
  const value = get(record, source);
  const { getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });
  const choice: any =
    choices && choices.find((choice) => getChoiceValue(choice) === value);
  const ChoiceIcon = choice && choice.icon ? choice.icon : null;

  return (
    <Chip sx={{ backgroundColor: choice.color || 'grey.500' }}>
      {ChoiceIcon && (
        <ChoiceIcon
          sx={{
            fontSize: 18,
            ml: -0.2,
            mr: 0.5,
            pb: 0.3,
          }}
        />
      )}
      <SelectField sx={{ pt: '0.1rem' }} {...props} />
    </Chip>
  );
};

export default SelectChipField;
