import * as React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import roleChoices from '../../../choices/auth/auth-role.choices';

const UserTitle = () => {
  const record = useRecordContext<UserType>();
  return <span>{record ? `${record.firstName} ${record.lastName}` : ''}</span>;
};

const UserEdit = () => (
  <Edit title={<UserTitle />}>
    <SimpleForm>
      <TextInput
        disabled
        source="firstName"
        label="resource.users.field.firstName"
      />
      <TextInput
        disabled
        source="lastName"
        label="resource.users.field.lastName"
      />
      <TextInput disabled source="email" label="resource.users.field.email" />
      <DateTimeInput
        disabled
        source="createdAt"
        label="resource.common.field.createdAt"
      />
      <SelectInput
        source="role"
        label="resource.users.field.role.name"
        choices={roleChoices}
      />
      <BooleanInput
        source="isActivated"
        label="resource.users.field.isActivated"
      />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
