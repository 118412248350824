import * as React from 'react';
import {
  Create,
  DateTimeInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import TagsReferenceArrayInput from '../../components/post/TagsReferenceArrayInput';
import criticTypeChoices from '../../../choices/critics/critic-type.choices';
import S3ImageInput from '../../components/common/S3ImageInput';

const CriticCreate = () => {
  const translate = useTranslate();

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.articles.name', {
          smart_count: 1,
        }),
      })}
    >
      <SimpleForm>
        <DateTimeInput
          source="date"
          label="resource.global.field.date"
          defaultValue={new Date()}
          isRequired
          validate={required()}
        />
        <SelectInput
          source="type"
          label="resource.critics.field.type.name"
          choices={criticTypeChoices}
          isRequired
          validate={required()}
        />
        <TextInput
          source="title"
          label="resource.global.field.title"
          isRequired
          validate={required()}
        />
        <NumberInput
          source="rating"
          step={1}
          min={0}
          max={10}
          isRequired
          validate={required()}
        />
        <TagsReferenceArrayInput />
        <S3ImageInput
          source="thumbnail"
          label="resource.global.field.thumbnail"
          isRequired
          validate={required()}
        />
        <CustomRichTextInput
          source="content"
          label="resource.critics.field.content"
          isRequired
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default CriticCreate;
