import CriticIcon from '@mui/icons-material/Message';

import CriticsList from './CriticsList';
import CriticCreate from './CriticCreate';
import CriticEdit from './CriticEdit';

const Critic = {
  list: CriticsList,
  create: CriticCreate,
  edit: CriticEdit,
  icon: CriticIcon,
};

export default Critic;
