import * as React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import postStatusChoices from '../../../choices/post/post-status.choices';
import TagsReferenceArrayInput from '../../components/post/TagsReferenceArrayInput';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import S3ImageInput from '../../components/common/S3ImageInput';

const GalleryTitle = () => {
  const record = useRecordContext<GalleryType>();
  return <span>{record ? record.title : ''}</span>;
};

const GalleryEdit = () => (
  <Edit title={<GalleryTitle />}>
    <SimpleForm>
      <TextInput disabled source="id" label="resource.common.field.id" />
      <DateTimeInput
        source="date"
        label="resource.global.field.date"
        defaultValue={new Date()}
        isRequired
        validate={required()}
      />
      <TextInput
        source="title"
        label="resource.global.field.title"
        isRequired
        validate={required()}
      />
      <CustomRichTextInput
        source="content"
        label="resource.galleries.field.content"
      />
      <SelectInput
        source="status"
        label="resource.posts.field.status.name"
        choices={postStatusChoices}
        isRequired
        validate={required()}
      />
      <TagsReferenceArrayInput />
      <S3ImageInput
        source="pictures"
        label="resource.galleries.field.pictures"
        multiple
        select="cover"
        isRequired
      />
      <BooleanInput
        source="isPinned"
        label="resource.galleries.field.isPinned"
      />
    </SimpleForm>
  </Edit>
);

export default GalleryEdit;
