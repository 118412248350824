import * as React from 'react';
import {
  Create,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import ExistingTags from '../../components/tags/ExistingTags';

interface Props {
  setCreatedTags: React.Dispatch<React.SetStateAction<number>>;
}

const CustomToolbar = ({ setCreatedTags }: Props) => {
  const notify = useNotify();
  const { reset } = useFormContext();

  return (
    <Toolbar>
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: () => {
            reset();
            setCreatedTags((createdTags) => createdTags + 1);
            window.scrollTo(0, 0);
            notify('ra.notification.created', {
              type: 'info',
              messageArgs: { smart_count: 1 },
            });
          },
        }}
      />
    </Toolbar>
  );
};

const TagCreate = () => {
  const translate = useTranslate();
  const [createdTags, setCreatedTags] = React.useState<number>(0);

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.tags.name', {
          smart_count: 1,
        }),
      })}
    >
      <SimpleForm toolbar={<CustomToolbar setCreatedTags={setCreatedTags} />}>
        <ExistingTags createdTags={createdTags} />
        <TextInput
          source="name"
          label="resource.global.field.name"
          isRequired
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default TagCreate;
