import * as React from 'react';
import {
  ButtonProps,
  useNotify,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from 'react-admin';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArchiveIcon from '@mui/icons-material/Archive';
import CustomButton from '../common/CustomButton';
import { PostStatus } from '../../../utils/enum';

const UpdateStatusButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  let newStatus = PostStatus.PUBLISHED;
  let label = 'resource.posts.action.publish';
  let icon = <AssignmentTurnedInIcon />;
  if (record && record.status === PostStatus.PUBLISHED) {
    newStatus = PostStatus.ARCHIVED;
    label = 'resource.posts.action.archive';
    icon = <ArchiveIcon />;
  }
  const notify = useNotify();
  const [updateStatus, { isLoading }] = useUpdate(
    resource,
    {
      id: record ? record.id : 0,
      data: { ...record, status: newStatus },
      previousData: record,
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('resource.posts.action.statusUpdated', {
          type: 'info',
          undoable: true,
        });
      },
      onError: (error: any) =>
        notify(`Error: ${error.message}`, { type: 'warning' }),
    },
  );

  return record ? (
    <CustomButton
      icon={icon}
      label={label}
      onClick={() => updateStatus()}
      disabled={isLoading}
    />
  ) : null;
};

export default UpdateStatusButton;
