import * as React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import postStatusChoices from '../../../choices/post/post-status.choices';

const postFilters = [
  <TextInput
    source="q"
    label="ra.action.search"
    resettable
    alwaysOn
    sx={{ mb: 0.5 }}
  />,
  <SelectInput
    source="status"
    label="resource.posts.field.status.name"
    choices={postStatusChoices}
    alwaysOn
  />,
  <ReferenceInput
    source="tagId"
    label="resource.posts.field.tag"
    reference="tags"
  >
    <AutocompleteInput optionText="name" label="resource.posts.field.tag" />
  </ReferenceInput>,
];

export default postFilters;
