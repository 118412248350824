import * as React from 'react';
import { Validator } from 'react-admin';
import { Box } from '@mui/material';
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  ImageButtons,
  LevelSelect,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputToolbar,
} from 'ra-input-rich-text';

interface Props {
  source: string;
  label?: string;
  isRequired?: boolean;
  validate?: Validator | Validator[];
  fullWidth?: boolean;
  withImage?: boolean;
  [key: string]: any;
}

const CustomRichTextInput: React.FC<Props> = (props: Props) => {
  const {
    source,
    label,
    isRequired = false,
    validate,
    fullWidth = true,
    withImage = false,
  } = props;

  return (
    <Box
      sx={{
        width: '100%',
        '& .RaRichTextInput-editorContent #content': {
          minHeight: '10rem',
          maxHeight: '75vh',
          overflowY: 'scroll',
        },
      }}
    >
      <RichTextInput
        source={source}
        label={label}
        toolbar={
          <RichTextInputToolbar>
            <LevelSelect />
            <FormatButtons />
            <ColorButtons />
            <AlignmentButtons />
            <ListButtons />
            <QuoteButtons />
            <LinkButtons />
            {withImage && <ImageButtons />}
            <ClearButtons />
          </RichTextInputToolbar>
        }
        isRequired={isRequired}
        validate={validate}
        fullWidth={fullWidth}
      />
    </Box>
  );
};

export default CustomRichTextInput;
