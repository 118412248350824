import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslate } from 'react-admin';
import customDataProvider from '../../../providers/data-provider';
import {
  getDataFromWebService,
  getOptions,
  height,
} from '../../../utils/chart';

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const PostsByDateChart: React.FC = () => {
  const theme = useTheme();
  const translate = useTranslate();
  const [postsCount, setPostsCount] = React.useState<any>({});

  React.useEffect(() => {
    customDataProvider
      .request('/posts/count/date')
      .then((response) => setPostsCount(response));
  }, []);

  const data = getDataFromWebService(
    postsCount,
    translate,
    'month',
    true,
    'month.',
  );

  const options = getOptions(theme, true, 'top');

  return <Line height={height} data={data} options={options} />;
};

export default PostsByDateChart;
