import * as React from 'react';
import { FC, useState } from 'react';
import DashboardIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import {
  MenuItemLink,
  MenuProps,
  usePermissions,
  useSidebarState,
  useTranslate,
} from 'react-admin';

import articles from '../resources/articles';
import stories from '../resources/stories';
import galleries from '../resources/galleries';
import critics from '../resources/critics';
import tags from '../resources/tags';
import users from '../resources/users';
import apiKeys from '../resources/api-keys';
import SubMenu from './SubMenu';
import { AuthRoles } from '../../utils/enum';

type MenuName = 'adminMenu';

const Menu: FC<MenuProps> = ({ dense = false }) => {
  const { permissions } = usePermissions();
  const [state, setState] = useState({
    adminMenu: false,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box sx={{ mt: 1 }}>
      <MenuItemLink
        to="/dashboard"
        state={{ _scrollToTop: true }}
        primaryText={translate('ra.page.dashboard')}
        leftIcon={<DashboardIcon />} // pass the icon here
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/articles`}
        state={{ _scrollToTop: true }}
        primaryText={translate(`resource.articles.name`, {
          smart_count: 2,
        })}
        leftIcon={<articles.icon />}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/stories`}
        state={{ _scrollToTop: true }}
        primaryText={translate(`resource.stories.name`, {
          smart_count: 2,
        })}
        leftIcon={<stories.icon />}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/galleries`}
        state={{ _scrollToTop: true }}
        primaryText={translate(`resource.galleries.name`, {
          smart_count: 2,
        })}
        leftIcon={<galleries.icon />}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/critics`}
        state={{ _scrollToTop: true }}
        primaryText={translate(`resource.critics.name`, {
          smart_count: 2,
        })}
        leftIcon={<critics.icon />}
        sidebarIsOpen={open}
        dense={dense}
      />
      {[AuthRoles.PUBLISHER, AuthRoles.ADMIN].includes(permissions) && (
        <MenuItemLink
          to={`/tags`}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resource.tags.name`, {
            smart_count: 2,
          })}
          leftIcon={<tags.icon />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions === AuthRoles.ADMIN && (
        <SubMenu
          handleToggle={() => handleToggle('adminMenu')}
          isOpen={state.adminMenu}
          sidebarIsOpen={open}
          name="resource.administration.name"
          icon={<SettingsIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/api-keys`}
            state={{ _scrollToTop: true }}
            primaryText={translate(`resource.api-keys.name`, {
              smart_count: 2,
            })}
            leftIcon={<apiKeys.icon />}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/users`}
            state={{ _scrollToTop: true }}
            primaryText={translate(`resource.users.name`, {
              smart_count: 2,
            })}
            leftIcon={<users.icon />}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}
    </Box>
  );
};

export default Menu;
