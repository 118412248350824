import * as React from 'react';
import { TextFieldProps, useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';
import get from 'lodash/get';

// Makes the source property mandatory
interface Props extends TextFieldProps {
  source: string;
  label?: string;
  value?: string;
}

const CodeSnippetField: React.FC<Props> = (props) => {
  const { source, value } = props;
  const record = useRecordContext(props);
  const codeSnippet = value ? value : record ? get(record, source) : '';
  let codeSnippetParts = codeSnippet
    ? codeSnippet
        .split(/([a-zA-Z0-9]+)/)
        .map((p: string) => p.split(/(\d+)/))
        .flat(1)
        .filter((p: string) => p.length > 0)
    : [];

  return (
    <Box display="flex">
      <Box
        sx={{
          backgroundColor: '#151617',
          py: 0.3,
          px: 0.5,
          fontFamily:
            'Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,' +
            'Courier New,monospace,sans-serif',
          color: '#e8e6e3',
        }}
      >
        {codeSnippetParts.map((codeSnippetPart: string, i: number) => (
          <Typography
            key={`code-snippet-part-${i}`}
            sx={{
              display: 'inline',
              color: /^[a-zA-Z]+$/.test(codeSnippetPart)
                ? '#bac172'
                : /^\d+$/.test(codeSnippetPart)
                ? '#f19250'
                : undefined,
            }}
          >
            {codeSnippetPart}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default CodeSnippetField;
