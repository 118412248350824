import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from 'react-admin';
import { lightTheme } from '../layouts/themes';

interface FormValues {
  email?: string;
  password?: string;
}

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(
      auth,
      location.state ? (location.state as any).nextPathname : '/dashboard',
    ).catch((error: Error) => {
      setLoading(false);
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : error.message,
        {
          type: 'warning',
          messageArgs: {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        },
      );
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate('ra.validation.required');
    } else if (!validateEmail(values.email)) {
      errors.email = translate('ra.validation.email');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'url(/images/login.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Card sx={{ width: 350 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              m: 2,
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box sx={{ px: 3, pt: 1 }}>
            <TextInput
              autoFocus
              source="email"
              label={translate('ra.auth.email')}
              sx={{ mb: 1 }}
              disabled={loading}
              validate={required()}
              fullWidth
            />
            <TextInput
              source="password"
              label={translate('ra.auth.password')}
              type="password"
              disabled={loading}
              validate={required()}
              fullWidth
            />
          </Box>
          <CardActions sx={{ p: 3, pt: 1 }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  sx={{ mr: 1, mb: 0.5 }}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => {
  const storedTheme = localStorage.getItem('RaStore.theme');
  const theme = storedTheme ? JSON.parse(storedTheme) : lightTheme;
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Login />
    </ThemeProvider>
  );
};

export default LoginWithTheme;
