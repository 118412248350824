import { Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

const FormLabel: React.FC<Props> = ({ children }) => {
  return (
    <Typography
      component="label"
      className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
      data-shrink="true"
      htmlFor="content"
      sx={{ position: 'relative' }}
    >
      <span>{children}</span>
    </Typography>
  );
};

export default FormLabel;
