import {
  amber,
  brown,
  cyan,
  deepPurple,
  orange,
  red,
} from '@mui/material/colors';
import CasinoIcon from '@mui/icons-material/Casino';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MovieIcon from '@mui/icons-material/Movie';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import MuseumIcon from '@mui/icons-material/Museum';
import { CriticType } from '../../utils/enum';

const criticTypeChoices = [
  {
    id: CriticType.GAME,
    name: 'resource.critics.field.type.game',
    color: amber[500],
    icon: CasinoIcon,
  },
  {
    id: CriticType.BOOK,
    name: 'resource.critics.field.type.book',
    color: orange[500],
    icon: MenuBookIcon,
  },
  {
    id: CriticType.MOVIE,
    name: 'resource.critics.field.type.movie',
    color: deepPurple[500],
    icon: MovieIcon,
  },
  {
    id: CriticType.TV_SHOW,
    name: 'resource.critics.field.type.tvShow',
    color: cyan[500],
    icon: LiveTvIcon,
  },
  {
    id: CriticType.SHOW,
    name: 'resource.critics.field.type.show',
    color: red[500],
    icon: TheaterComedyIcon,
  },
  {
    id: CriticType.EXPOSITION,
    name: 'resource.critics.field.type.exposition',
    color: brown[500],
    icon: MuseumIcon,
  },
];

export default criticTypeChoices;
