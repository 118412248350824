import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';
import { useTranslate } from 'react-admin';
import customDataProvider from '../../../providers/data-provider';
import {
  getDataFromWebService,
  getOptions,
  height,
} from '../../../utils/chart';

const PostsByTagChart: React.FC = () => {
  const theme = useTheme();
  const translate = useTranslate();
  const [postsCount, setPostsCount] = React.useState<any>({});

  React.useEffect(() => {
    customDataProvider
      .request('/posts/count/tag')
      .then((response) => setPostsCount(response));
  }, []);

  const data = getDataFromWebService(postsCount, translate, 'tag');

  const options = getOptions(theme, true);

  return <Bar height={height} data={data} options={options} />;
};

export default PostsByTagChart;
