import * as React from 'react';
import {
  AutocompleteArrayInput,
  InputProps,
  ReferenceArrayInput,
  useTranslate,
} from 'react-admin';

const TagsReferenceArrayInput: React.FC<Omit<InputProps, 'source'>> = (
  props,
) => {
  const translate = useTranslate();

  return (
    <ReferenceArrayInput source="tagsIds" reference="tags">
      <AutocompleteArrayInput
        label={translate('resource.tags.name', {
          smart_count: 2,
        })}
        optionText="name"
        fullWidth
      />
    </ReferenceArrayInput>
  );
};

export default TagsReferenceArrayInput;
