import * as React from 'react';
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import {
  RaRecord,
  useCreatePath,
  useListContext,
  usePermissions,
} from 'react-admin';
import { AuthRoles } from '../../../utils/enum';
import UserField from '../users/UserField';
import { Link } from 'react-router-dom';
import PushPinIcon from '@mui/icons-material/PushPin';
import { blue } from '@mui/material/colors';
import SelectChipField from '../common/SelectChipField';
import postStatusChoices from '../../../choices/post/post-status.choices';
import { ImageListProps } from '../common/CustomImageList';

interface SubtitleProps {
  record: RaRecord;
}

interface Props extends ImageListProps {
  resource: string;
  imageSource: string;
  getSubtitleFromRecord: (record: RaRecord) => string;
  isPinnable?: boolean;
  postTypeChoices?: object[];
}

const ItemSubtitle: React.FC<SubtitleProps> = ({ record }) => {
  const { permissions } = usePermissions();

  return (
    <>
      {[AuthRoles.PUBLISHER, AuthRoles.ADMIN].includes(permissions) && (
        <UserField record={record} source="author" linkable={false} />
      )}
    </>
  );
};

const PostsImageList: React.FC<Props> = ({
  rowHeight,
  cols,
  sx,
  resource,
  imageSource,
  getSubtitleFromRecord,
  isPinnable,
  postTypeChoices,
}) => {
  const { data } = useListContext();
  const createPath = useCreatePath();

  return data ? (
    <ImageList rowHeight={rowHeight} cols={cols} sx={sx}>
      {data.length > 0 &&
        data.map((record: RaRecord) => (
          <ImageListItem
            component={Link}
            key={record.id}
            to={createPath({
              resource,
              id: record.id,
              type: 'edit',
            })}
          >
            <img src={record[imageSource]} alt={imageSource} />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                background:
                  'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
                '&:hover .subtitle': {
                  opacity: 1,
                },
                '& .subtitle': {
                  opacity: 0,
                  transition: 'opacity ease-in-out 0.5s',
                  fontSize: '1.5rem',
                  color: '#fff',
                },
              }}
            >
              <Typography className="subtitle">
                {getSubtitleFromRecord(record)}
              </Typography>
            </Box>
            {postTypeChoices && (
              <Box sx={{ position: 'absolute', top: '0.1rem', left: '0.1rem' }}>
                <SelectChipField
                  record={record}
                  source="type"
                  choices={postTypeChoices}
                />
              </Box>
            )}
            {isPinnable && record.isPinned && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0.1rem',
                  left: '0.1rem',
                  backgroundColor: blue[500],
                  borderRadius: '100%',
                  color: 'white',
                }}
              >
                <PushPinIcon sx={{ fontSize: 20, p: 0.5 }} />
              </Box>
            )}
            <Box sx={{ position: 'absolute', top: '0.1rem', right: '0.1rem' }}>
              <SelectChipField
                record={record}
                source="status"
                choices={postStatusChoices}
              />
            </Box>
            <ImageListItemBar
              sx={{
                height: 'auto',
                paddingBottom: '0.2rem',
                background: 'transparent',
              }}
              title={record.title}
              subtitle={<ItemSubtitle record={record} />}
            />
          </ImageListItem>
        ))}
    </ImageList>
  ) : null;
};

export default PostsImageList;
