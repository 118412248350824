import * as React from 'react';
import {
  Create,
  DateTimeInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import TagsReferenceArrayInput from '../../components/post/TagsReferenceArrayInput';

const StoryCreate = () => {
  const translate = useTranslate();

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.stories.name', {
          smart_count: 1,
        }),
      })}
    >
      <SimpleForm>
        <DateTimeInput
          source="date"
          label="resource.global.field.date"
          defaultValue={new Date()}
          isRequired
          validate={required()}
        />
        <TextInput
          source="title"
          label="resource.global.field.title"
          isRequired
          validate={required()}
        />
        <TagsReferenceArrayInput />
        <CustomRichTextInput
          source="content"
          label="resource.stories.field.content"
          isRequired
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default StoryCreate;
