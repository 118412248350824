import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { useTranslate } from 'react-admin';
import customDataProvider from '../../../providers/data-provider';
import { COLORS, getOptions, height } from '../../../utils/chart';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const PostsTypesChart: React.FC = () => {
  const theme = useTheme();
  const translate = useTranslate();
  const [postsCount, setPostsCount] = React.useState<any>({});

  React.useEffect(() => {
    customDataProvider
      .request('/posts/count')
      .then((response) => setPostsCount(response));
  }, []);

  const data = {
    labels: Object.keys(postsCount).map((key) =>
      translate(`resource.${key}.name`, {
        smart_count: 2,
      }),
    ),
    datasets: [
      {
        data: Object.values(postsCount),
        backgroundColor: COLORS,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = getOptions(theme);

  return <Doughnut height={height} data={data} options={options} />;
};

export default PostsTypesChart;
