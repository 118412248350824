import { AuthRoles } from '../../utils/enum';
import { blue, deepPurple, red } from '@mui/material/colors';
import SecurityIcon from '@mui/icons-material/Security';
import FaceIcon from '@mui/icons-material/Face';

const roleChoices = [
  {
    id: AuthRoles.ADMIN,
    name: 'resource.users.field.role.admin',
    color: red[500],
    icon: SecurityIcon,
  },
  {
    id: AuthRoles.PUBLISHER,
    name: 'resource.users.field.role.publisher',
    color: deepPurple[500],
    icon: FaceIcon,
  },
  {
    id: AuthRoles.EDITOR,
    name: 'resource.users.field.role.editor',
    color: blue[500],
    icon: FaceIcon,
  },
];

export default roleChoices;
