import * as React from 'react';
import { Box, SxProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}

const Chip: React.FC<Props> = ({ children, sx }: Props) => (
  <Box display="flex">
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 0.5,
        color: 'white',
        px: 1,
        py: 0.5,
        backgroundColor: 'grey.500',
        ...sx,
      }}
    >
      {children}
    </Box>
  </Box>
);

export default Chip;
