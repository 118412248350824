import ArticleIcon from '@mui/icons-material/Assignment';

import ArticlesList from './ArticlesList';
import ArticleCreate from './ArticleCreate';
import ArticleEdit from './ArticleEdit';

const Article = {
  list: ArticlesList,
  create: ArticleCreate,
  edit: ArticleEdit,
  icon: ArticleIcon,
};

export default Article;
