import * as React from 'react';
import {
  Create,
  DateTimeInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import S3ImageInput from '../../components/common/S3ImageInput';
import TagsReferenceArrayInput from '../../components/post/TagsReferenceArrayInput';

const ArticleCreate = () => {
  const translate = useTranslate();

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.articles.name', {
          smart_count: 1,
        }),
      })}
    >
      <SimpleForm>
        <DateTimeInput
          source="date"
          label="resource.global.field.date"
          defaultValue={new Date()}
          isRequired
          validate={required()}
        />
        <TextInput
          source="title"
          label="resource.global.field.title"
          isRequired
          validate={required()}
        />
        <TagsReferenceArrayInput />
        <S3ImageInput
          source="thumbnail"
          label="resource.global.field.thumbnail"
        />
        <CustomRichTextInput
          source="content"
          label="resource.articles.field.content"
          withImage
          isRequired
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default ArticleCreate;
