import * as React from 'react';
import { Box, Typography } from '@mui/material';

const Logo = () => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Box
      component="img"
      src="/images/logo.png"
      alt="logo"
      sx={{ height: 25, pb: 1 }}
    />
    <Typography variant="h5" sx={{ ml: 1 }}>
      BlogNote
    </Typography>
  </Box>
);

export default Logo;
