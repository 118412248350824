import * as React from 'react';
import { useListContext } from 'react-admin';
import { SxProps, useMediaQuery, useTheme } from '@mui/material';
import LoadingImageList from './LoadingImageList';

const useColsForWidth = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  // there are all dividers of 12, to have full rows on each page
  if (lg) return 6;
  if (md) return 4;
  if (sm) return 3;
  return 2;
};

export interface ImageListProps {
  rowHeight?: number | 'auto';
  cols?: number;
  sx?: SxProps;
}

interface Props {
  loadedImageList: React.ReactElement;
  rowHeight?: number;
}

const CustomImageList: React.FC<Props> = ({
  loadedImageList,
  rowHeight = 180,
}) => {
  const { isLoading } = useListContext();
  const cols = useColsForWidth();
  const sx = { m: 0 };

  return isLoading ? (
    <LoadingImageList rowHeight={rowHeight} cols={cols} sx={sx} />
  ) : (
    React.cloneElement(loadedImageList, {
      rowHeight,
      cols,
      sx,
    })
  );
};

export default CustomImageList;
