import ApiKeyIcon from '@mui/icons-material/VpnKey';

import ApiKeysList from './ApiKeysList';
import ApiKeyCreate from './ApiKeyCreate';
import ApiKeyEdit from './ApiKeyEdit';

const ApiKey = {
  list: ApiKeysList,
  create: ApiKeyCreate,
  edit: ApiKeyEdit,
  icon: ApiKeyIcon,
};

export default ApiKey;
