import * as React from 'react';
import { FieldProps, Link, TextField, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import GravatarField from './GravatarField';
import get from 'lodash/get';

// Makes the source property mandatory
interface UserFieldContentProps extends FieldProps {
  source: string;
}

interface UserFieldProps extends UserFieldContentProps {
  linkable?: boolean;
}

const UserFieldContent: React.FC<UserFieldContentProps> = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const user = get(record, source);

  return user ? (
    <Box display="flex" alignItems="center">
      <GravatarField record={user} size={25} rounded />
      <Box sx={{ ml: 1 }}>
        <TextField record={user} source="firstName" />
        <TextField
          source="lastName"
          record={user}
          style={{ marginLeft: '0.25rem' }}
        />
      </Box>
    </Box>
  ) : null;
};

const stopPropagation = (e: any) => e.stopPropagation();

const UserField: React.FC<UserFieldProps> = (props) => {
  const { source, linkable = true } = props;
  const record = useRecordContext(props);
  const user = get(record, source);

  return user ? (
    <>
      {linkable ? (
        <Link to={`/users/${user.id}`} onClick={stopPropagation}>
          <UserFieldContent record={record} source={source} />
        </Link>
      ) : (
        <UserFieldContent record={record} source={source} />
      )}
    </>
  ) : null;
};

export default UserField;
