import * as React from 'react';
import { Toolbar, ToolbarProps } from 'react-admin';

const CustomToolbar: React.FC<Omit<ToolbarProps, 'width'>> = (props) => (
  <Toolbar
    sx={{
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    {props.children}
  </Toolbar>
);

export default CustomToolbar;
