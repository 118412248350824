import * as React from 'react';
import { BooleanField, BooleanFieldProps, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import { green, red } from '@mui/material/colors';
import get from 'lodash/get';

// Makes the source property mandatory
interface Props extends BooleanFieldProps {
  source: string;
}

const BooleanColoredField: React.FC<Props> = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = !!get(record, source);

  return (
    <Box sx={{ color: value ? green[500] : red[500] }}>
      <BooleanField {...props} />
    </Box>
  );
};

export default BooleanColoredField;
