import * as React from 'react';
import {
  AppBar,
  Logout,
  MenuItemLink,
  ToggleThemeButton,
  UserMenu,
  useTranslate,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import profile from '../resources/profile';
import Logo from './Logo';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from './themes';

const ConfigurationMenu = React.forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  const { icon: ProfileIcon } = profile;

  return (
    <>
      <MenuItemLink
        ref={ref}
        to="/profile"
        primaryText={translate('resource.profile.name')}
        leftIcon={<ProfileIcon />}
        onClick={props.onClick}
        sidebarIsOpen
      />
    </>
  );
});

const CustomUserMenu = () => {
  return (
    <UserMenu>
      <ConfigurationMenu />
      <Logout />
    </UserMenu>
  );
};

const CustomAppBar = (props: any) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm'),
  );
  return (
    <AppBar
      {...props}
      color="secondary"
      elevation={1}
      userMenu={<CustomUserMenu />}
    >
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />
      {isLargeEnough && <Logo />}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
      <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
    </AppBar>
  );
};

export default CustomAppBar;
