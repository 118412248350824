import * as React from 'react';
import {
  BooleanInput,
  Create,
  DateTimeInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import TagsReferenceArrayInput from '../../components/post/TagsReferenceArrayInput';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import S3ImageInput from '../../components/common/S3ImageInput';

const GalleryCreate = () => {
  const translate = useTranslate();

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.galleries.name', {
          smart_count: 1,
        }),
      })}
    >
      <SimpleForm>
        <DateTimeInput
          source="date"
          label="resource.global.field.date"
          defaultValue={new Date()}
          isRequired
          validate={required()}
        />
        <TextInput
          source="title"
          label="resource.global.field.title"
          isRequired
          validate={required()}
        />
        <CustomRichTextInput
          source="content"
          label="resource.galleries.field.content"
        />
        <TagsReferenceArrayInput />
        <S3ImageInput
          source="pictures"
          label="resource.galleries.field.pictures"
          multiple
          select="cover"
          isRequired
        />
        <BooleanInput
          source="isPinned"
          label="resource.galleries.field.isPinned"
        />
      </SimpleForm>
    </Create>
  );
};

export default GalleryCreate;
