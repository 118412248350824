import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from '../i18n/en';
import frenchMessages from '../i18n/fr';

const defaultLanguage =
  (localStorage.getItem('RaStore.locale') as string) || 'fr';

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'en') {
    return englishMessages;
  }

  // Always fallback on french
  return frenchMessages;
}, defaultLanguage);

export default i18nProvider;
