import * as React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import CodeSnippetField from '../../components/common/CodeSnippetField';
import FormLabel from '../../components/common/FormLabel';
import FormControl from '../../components/common/FormControl';

const ApiKeyTitle = () => {
  const record = useRecordContext<ApiKeyType>();
  return <span>{record ? record.name : ''}</span>;
};

const ApiKeyEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<ApiKeyTitle />}>
      <SimpleForm>
        <TextInput disabled source="id" label="resource.common.field.id" />
        <TextInput
          source="name"
          label="resource.global.field.name"
          isRequired
          validate={required()}
        />
        <FormControl>
          <FormLabel>{translate('resource.global.field.value')}</FormLabel>
          <CodeSnippetField source="value" />
        </FormControl>
      </SimpleForm>
    </Edit>
  );
};

export default ApiKeyEdit;
