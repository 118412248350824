import * as React from 'react';
import { FieldProps, useRecordContext, useResourceContext } from 'react-admin';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

// Makes the source prop mandatory
interface Props extends FieldProps {
  source: string;
}

const RefField: React.FC<Props> = (props) => {
  const { source }: Props = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const value = get(record, source);

  return (
    <Box sx={{ '& a': { color: 'inherit' } }}>
      <Link to={`/${resource}/${record.id}`}>{value}</Link>
    </Box>
  );
};

export default RefField;
