import * as React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  SimpleList,
  TextField,
  usePermissions,
  useTranslate,
} from 'react-admin';
import postStatusChoices from '../../../choices/post/post-status.choices';
import UpdateStatusButton from '../../components/post/UpdateStatusButton';
import { AuthRoles } from '../../../utils/enum';
import UserField from '../../components/users/UserField';
import SelectChipField from '../../components/common/SelectChipField';
import { Theme, useMediaQuery } from '@mui/material';
import postFilters from '../../components/post/postFilters';

const StoriesList = () => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true },
  );
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  return (
    <List
      title={translate(`resource.stories.name`, {
        smart_count: 2,
      })}
      filters={postFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) =>
            translate(
              `resource.posts.field.status.${record.status.toLowerCase()}`,
            )
          }
          tertiaryText={(record) =>
            new Date(record.updatedAt).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid>
          <TextField source="title" label="resource.global.field.title" />
          <SelectChipField
            source="status"
            choices={postStatusChoices}
            label="resource.posts.field.status.name"
          />
          {[AuthRoles.PUBLISHER, AuthRoles.ADMIN].includes(permissions) && (
            <UserField source="author" label="resource.posts.field.author" />
          )}
          <DateField
            source="createdAt"
            label="resource.common.field.createdAt"
            locales="fr-FR"
            showTime
          />
          <DateField
            source="updatedAt"
            label="resource.common.field.updatedAt"
            locales="fr-FR"
            showTime
          />
          <EditButton />
          {(permissions === AuthRoles.EDITOR || isLarge) && (
            <UpdateStatusButton />
          )}
        </Datagrid>
      )}
    </List>
  );
};

export default StoriesList;
