import * as React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import { PostStatus } from '../../../utils/enum';
import customDataProvider from '../../../providers/data-provider';
import { PostType } from '../../resources/types';

interface PostDraftsWidgetProps {
  resource: string;
  icon: React.FC<any>;
}

const PostDraftsWidget: React.FC<PostDraftsWidgetProps> = ({
  resource,
  icon,
}: PostDraftsWidgetProps) => {
  const translate = useTranslate();
  const [posts, setPosts] = React.useState<any>({ data: [], total: 0 });

  React.useEffect(() => {
    customDataProvider
      .getList(resource, {
        pagination: {
          page: 1,
          perPage: 10,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter: { status: PostStatus.DRAFT },
      })
      .then((response) => setPosts(response));
  }, [resource]);

  return (
    <CardWithIcon
      icon={icon}
      title={translate('screen.dashboard.field.drafts')}
      value={posts.total}
    >
      <List>
        {posts.data.map((post: PostType) => (
          <ListItem
            button
            to={`/${resource}/${post.id}`}
            component={Link}
            key={post.id}
          >
            <ListItemText primary={post.title} />
          </ListItem>
        ))}
      </List>
    </CardWithIcon>
  );
};

export default PostDraftsWidget;
