import * as React from 'react';
import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  ReferenceManyFieldProps,
  SimpleList,
  usePermissions,
  useTranslate,
} from 'react-admin';
import RefField from '../common/RefField';
import SelectChipField from '../common/SelectChipField';
import postStatusChoices from '../../../choices/post/post-status.choices';
import FormLabel from '../common/FormLabel';
import FormControl from '../common/FormControl';
import { AuthRoles } from '../../../utils/enum';
import UserField from '../users/UserField';

enum TYPE {
  SIMPLE_LIST,
  DATA_GRID,
}

interface PostReferenceManyFieldsProps
  extends Omit<ReferenceManyFieldProps, 'target' | 'children'> {
  type?: TYPE;
  showPagination?: boolean;
}

const PostReferenceManyFields: React.FC<PostReferenceManyFieldsProps> = (
  props: PostReferenceManyFieldsProps,
) => {
  const {
    reference,
    type = TYPE.DATA_GRID,
    showPagination = true,
    ...rest
  } = props;
  const { permissions } = usePermissions();
  const translate = useTranslate();

  return (
    <FormControl>
      <FormLabel>
        {translate(`resource.${reference}.name`, {
          smart_count: 2,
        })}
      </FormLabel>
      <ReferenceManyField
        reference={reference}
        target="tagId"
        pagination={showPagination ? <Pagination /> : undefined}
        sort={{ field: 'createdAt', order: 'DESC' }}
        fullWidth
        {...rest}
      >
        {type === TYPE.DATA_GRID ? (
          <Datagrid>
            <RefField source="title" label="resource.global.field.title" />
            <SelectChipField
              source="status"
              choices={postStatusChoices}
              label="resource.posts.field.status.name"
            />
            {[AuthRoles.PUBLISHER, AuthRoles.ADMIN].includes(permissions) && (
              <UserField source="author" label="resource.posts.field.author" />
            )}
            <DateField
              source="createdAt"
              label="resource.common.field.createdAt"
              locales="fr-FR"
              showTime
            />
            <DateField
              source="updatedAt"
              label="resource.common.field.updatedAt"
              locales="fr-FR"
              showTime
            />
          </Datagrid>
        ) : (
          <SimpleList
            primaryText={(record) => record.title}
            secondaryText={(record) => record.status}
            tertiaryText={(record) =>
              new Date(record.updatedAt).toLocaleDateString()
            }
          />
        )}
      </ReferenceManyField>
    </FormControl>
  );
};

export default PostReferenceManyFields;
