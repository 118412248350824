import * as React from 'react';
import {
  ButtonProps,
  Datagrid,
  DateField,
  DeleteButton,
  DeleteButtonProps,
  EditButton,
  EmailField,
  FunctionField,
  List,
  RaRecord,
  SelectInput,
  SimpleList,
  TextInput,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from 'react-admin';
import booleanChoices from '../../../choices/common/boolean.choices';
import roleChoices from '../../../choices/auth/auth-role.choices';
import CustomButton from '../../components/common/CustomButton';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SelectChipField from '../../components/common/SelectChipField';
import BooleanColoredField from '../../components/common/BooleanColoredField';
import { Theme, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const userFilters = [
  <TextInput
    source="q"
    label="ra.action.search"
    resettable
    alwaysOn
    sx={{ mb: 0.5 }}
  />,
  <SelectInput
    source="isActivated"
    label="resource.users.field.isActivated"
    choices={booleanChoices}
    alwaysOn
  />,
  <SelectInput
    source="status"
    label="resource.users.field.role.name"
    choices={roleChoices}
    alwaysOn
  />,
];

const ActivateUserButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props);
  let label = 'resource.users.action.activate';
  let icon = <HowToRegIcon />;
  const notify = useNotify();
  const [updateSActivated, { isLoading }] = useUpdate(
    'users',
    {
      id: record ? record.id : 0,
      data: { ...record, isActivated: true },
      previousData: record,
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('resource.users.action.userActivated', {
          type: 'info',
          undoable: true,
        });
      },
      onError: (error: any) =>
        notify(`Error: ${error.message}`, { type: 'warning' }),
    },
  );

  return record ? (
    <CustomButton
      icon={icon}
      label={label}
      onClick={() => updateSActivated()}
      disabled={isLoading}
    />
  ) : null;
};

const UserEditButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props);
  const { identity } = useGetIdentity();
  if (!record || !identity || record.email === identity.email) {
    return null;
  } else if (record.isActivated) {
    return <EditButton />;
  } else {
    return <ActivateUserButton />;
  }
};

const UserDeleteButton: React.FC<DeleteButtonProps> = (props) => {
  const record = useRecordContext(props);
  const { identity } = useGetIdentity();

  if (!record || !identity || record.email === identity.email) {
    return null;
  } else {
    return <DeleteButton />;
  }
};

const UsersList = () => {
  const translate = useTranslate();
  const isSmall = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true },
  );
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  return (
    <List
      title={translate(`resource.users.name`, {
        smart_count: 2,
      })}
      filters={userFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.email}
          secondaryText={(record) =>
            translate(`resource.users.field.role.${record.role.toLowerCase()}`)
          }
          tertiaryText={(record) =>
            new Date(record.updatedAt).toLocaleDateString()
          }
          leftIcon={(record) =>
            record.isActivated ? <CheckIcon /> : <ClearIcon />
          }
        />
      ) : (
        <Datagrid>
          <SelectChipField
            source="role"
            choices={roleChoices}
            label="resource.users.field.role.name"
          />
          {isLarge && (
            <FunctionField
              render={(record?: RaRecord) =>
                record && `${record.firstName} ${record.lastName}`
              }
              label="resource.users.field.fullName"
            />
          )}
          )
          <EmailField source="email" label="resource.users.field.email" />
          <BooleanColoredField
            source="isActivated"
            label="resource.users.field.isActivated"
          />
          <DateField
            source="createdAt"
            label="resource.common.field.createdAt"
            locales="fr-FR"
            showTime
          />
          <DateField
            source="updatedAt"
            label="resource.common.field.updatedAt"
            locales="fr-FR"
            showTime
          />
          <UserEditButton />
          {isLarge && <UserDeleteButton />}
        </Datagrid>
      )}
    </List>
  );
};

export default UsersList;
