import * as React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import customDataProvider from '../../../providers/data-provider';
import apiKeyResource from '../../resources/api-keys';

const ApiKeysWidget: React.FC = () => {
  const translate = useTranslate();
  const [apiKeys, setApiKeys] = React.useState<any>({ data: [], total: 0 });

  React.useEffect(() => {
    customDataProvider
      .getList('api-keys', {
        pagination: {
          page: 1,
          perPage: 10,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter: undefined,
      })
      .then((response) => setApiKeys(response));
  }, []);

  return (
    <CardWithIcon
      icon={apiKeyResource.icon}
      title={translate('resource.api-keys.name', { smart_count: 2 })}
      value={apiKeys.total}
    >
      <List>
        {apiKeys.data.map((apiKey: ApiKeyType) => (
          <ListItem
            button
            to={`/api-keys/${apiKey.id}`}
            component={Link}
            key={apiKey.id}
          >
            <ListItemText primary={apiKey.name} />
          </ListItem>
        ))}
      </List>
    </CardWithIcon>
  );
};

export default ApiKeysWidget;
