import * as React from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';
import { ImageListProps } from './CustomImageList';
import { useListContext } from 'react-admin';

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingImageList: React.FC<ImageListProps> = ({
  rowHeight,
  cols,
  sx,
}) => {
  const { perPage } = useListContext();

  return (
    <ImageList rowHeight={rowHeight} cols={cols} sx={sx}>
      {times(perPage, (key) => (
        <ImageListItem key={key}>
          <Box sx={{ backgroundColor: 'grey.500', height: '100%' }} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default LoadingImageList;
