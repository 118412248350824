import { Theme } from '@mui/material';
import { LayoutPosition } from 'chart.js';
import { Translate } from 'react-admin';

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const height = 250;

export const getOptions = (
  theme: Theme,
  lineOptions = false,
  position: LayoutPosition = 'right',
) => ({
  tooltips: {
    displayColors: false,
    bodyFontSize: 16,
    xPadding: 10,
    yPadding: 8,
  },
  maintainAspectRatio: false,
  scales: lineOptions ? { y: { min: 0 } } : undefined,
  plugins: {
    legend: {
      display: true,
      position,
      onHover: (event: any) => (event.target.style.cursor = 'pointer'),
      onLeave: (event: any) => (event.target.style.cursor = 'default'),
      labels: {
        boxWidth: 20,
        font: {
          size: 18,
        },
        color: theme.palette.text.primary,
        padding: 20,
      },
    },
  },
});

export const getDataFromWebService = (
  postsCount: any,
  translate: Translate,
  chartType: 'month' | 'author' | 'tag',
  translateLabel = false,
  labelPrefix = '',
) => {
  return {
    labels: postsCount.articles?.map((value: any) =>
      translateLabel
        ? translate(labelPrefix + value[chartType])
        : labelPrefix + value[chartType],
    ),
    datasets: Object.keys(postsCount).map((postType: any, index) => {
      return {
        label: translate(`resource.${postType}.name`, { smart_count: 2 }),
        data: postsCount[postType].map((value: any) => value.totalCount),
        backgroundColor: COLORS[index],
        borderColor: COLORS[index],
        fill: false,
      };
    }),
  };
};
