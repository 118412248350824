import * as React from 'react';
import {
  Card,
  Box,
  Typography,
  SimplePaletteColorOptions,
} from '@mui/material';
import { useTheme } from 'react-admin';

interface CardWithIconProps {
  icon: React.FC<any>;
  title: string;
  value: number;
  children?: React.ReactNode;
}

const CardWithIcon: React.FC<CardWithIconProps> = (props) => {
  const { icon, title, value, children } = props;
  const [theme] = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Card
      sx={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: !!value ? 'pointer' : 'default',
        }}
        onClick={() => setOpen(!open)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            padding: '1rem',
            paddingRight: '2rem',
            alignItems: 'center',
            background: `linear-gradient(110deg, ${
              (theme?.palette?.primary as SimplePaletteColorOptions)?.main
            } 75%, transparent 76%)`,
            color: '#fff',
          }}
        >
          {React.createElement(icon, { fontSize: 'large', className: 'icon' })}
        </Box>
        <Box sx={{ my: '0.3rem', mr: '0.8rem', textAlign: 'right' }}>
          <Typography color="textSecondary">{title}</Typography>
          <Typography variant="h5">{value}</Typography>
        </Box>
      </Box>
      {open && !!value && (
        <Box
          sx={{ borderTop: `1px solid ${theme?.palette?.background?.default}` }}
        >
          {children}
        </Box>
      )}
    </Card>
  );
};

export default CardWithIcon;
