import { PostStatus } from '../../utils/enum';
import { blue, green, grey } from '@mui/material/colors';

const postStatusChoices = [
  {
    id: PostStatus.DRAFT,
    name: 'resource.posts.field.status.draft',
    color: grey[500],
  },
  {
    id: PostStatus.PUBLISHED,
    name: 'resource.posts.field.status.published',
    color: green[500],
  },
  {
    id: PostStatus.ARCHIVED,
    name: 'resource.posts.field.status.archived',
    color: blue[500],
  },
];

export default postStatusChoices;
