import * as React from 'react';
import { Admin, CustomRoutes, localStorageStore, Resource } from 'react-admin';
import i18nProvider from './providers/i18n-provider';
import authProvider from './providers/auth-provider';
import dataProvider from './providers/data-provider';
import Login from './ui/screens/Login';
import CustomLayout from './ui/layouts/Layout';
import Dashboard from './ui/screens/Dashboard';
import { lightTheme } from './ui/layouts/themes';
import { Route } from 'react-router-dom';
import profile from './ui/resources/profile';
import users from './ui/resources/users';
import apiKeys from './ui/resources/api-keys';
import tags from './ui/resources/tags';
import articles from './ui/resources/articles';
import stories from './ui/resources/stories';
import galleries from './ui/resources/galleries';
import critics from './ui/resources/critics';

function App() {
  const storedTheme = localStorage.getItem('RaStore.theme');
  const theme = storedTheme ? JSON.parse(storedTheme) : lightTheme;

  // Cancels reset of the local storage on logout
  const customStore = localStorageStore('1');
  customStore.reset = () => {};

  return (
    <Admin
      title="BlogNote"
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      store={customStore}
      theme={theme}
      loginPage={Login}
      layout={CustomLayout}
      dashboard={Dashboard}
      disableTelemetry
    >
      <CustomRoutes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<profile.edit />} />
      </CustomRoutes>
      <Resource name="users" {...users} />
      <Resource name="api-keys" {...apiKeys} />
      <Resource name="tags" {...tags} />
      <Resource name="articles" {...articles} />
      <Resource name="stories" {...stories} />
      <Resource name="galleries" {...galleries} />
      <Resource name="critics" {...critics} />
    </Admin>
  );
}

export default App;
